/* Utility classes {{{ */
.pull-center {
    margin: 0 auto;
    display: block;
    text-align: center;
}
/* }}} */
/* Logos: img.logo {{{ */
img.logo {
    max-width: 300px;
    margin: 20px auto;
}
/* }}} */
.text-warning {
	color:#f89406 !important
}
.text-warning:hover {
	color: #c67605 !important;
}
/* Bootstrap 2.x Badges {{{ */
.badge-error {
    background-color: #b94a48;
}
.badge-error:hover {
    background-color: #953b39;
}
.badge-warning {
    background-color: #f89406 !important;
}
.badge-warning:hover {
    background-color: #c67605 !important;
}
.badge-success {
    background-color: #468847 !important;
}
.badge-success:hover {
    background-color: #356635 !important;
}
.badge-info {
    background-color: #3a87ad;
}
.badge-info:hover {
    background-color: #2d6987;
}
.badge-inverse {
    background-color: #333333;
}
.badge-inverse:hover {
    background-color: #1a1a1a;
}
/* }}} */
/* alert.loading {{{ */
.alert.alert-loading {
  text-align: center;
  vertical-align: middle;
}

.alert.alert-loading > span {
  font-size: 25px;
}

.alert.alert-loading > i {
  width: auto;
  margin-right: 10px;
}

a.day.active {
  background: #ddd;
}
/* }}} */
/* Designer {{{ */
/* Badge on products should overlay the image when showing how many in a bundle remains {{{ */
.products-list figure > .badge {
	top: 5px;
	right: 5px;
	float: right;
	z-index: 1;
}
.products-list figure > .badge.product-qty {
	top: 67px;
}
/* }}} */
/* }}} */
/* ui-grid custom buttons {{{ */
.ui-grid-menu-button{
  width: 100%;
  background: none;
}
.ui-grid-icon-container{
  float: right;
}
.toolbar-item{
  float:right;
  width:45px;
}
/* }}} */
/* ui-grid load spinner {{{ */
/*
   This is the background of our overlay. We need it to be
   absolutely positioned within the grid, and fill from
   top to bottom, and the full width. It will also have
   a black background with 40% opacity.
*/
.grid-msg{
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
	text-align: center;
	z-index: 1000;
}

.grid-msg.grid-msg-overlay {
	background: rgba(0, 0, 0, 0.4);
}
/*
  This guy will contain our message. We want it centered
  so it's positioned absolutely with percentage-based
  offsets and dimensions. It also has some basic border
  stuff and most important is using "display: table" so
  we can vertically center its contents.
*/
.grid-msg .msg {
  opacity: 1;
  position: absolute;
  top: 20%;
  left: 20%;
  width: 60%;
  height: 50%;
  /*background-color: #eee;*/
  /*border-radius: 4px;*/
  /*border: 1px solid #555;*/
  text-align: center;
  font-size: 18px;
  display: table;
}
.noDataMessage .msg {
	top: 50%;
	font-size: 16px;
}
/*
  Lastly this is the actual message text. It uses
  display: table-cell so the vertical alignment
  works properly.
*/
.grid-msg-overlay .msg span {
  display: table-cell;
  vertical-align: middle;
}
.grid-msg-overlay .msg i {
	width: auto;
}
/* }}} */

table tr td.td-actions a {
	visibility: hidden;
}

table tr:hover td.td-actions a,
table tr:focus td.td-actions a,
table tr:active td.td-actions a {
	visibility: visible;
}

/* Quote report */
.version-note{
	white-space: pre-line !important;
}
.pad-clear-signature{
	float: right!important;
}
.pad-message{
	display: inline-block;
	width: 190px; /* TODO: sync with signature canvas dimensions*/
}
.body-designer #importJob .alert.alert-modal {
	position: static;
	top: auto;
	left: auto;
	min-height: 70px;
	overflow: auto;
	padding-top: 27px;
	padding-left: 23px;
}
.body-designer #importJob .alert.alert-modal i {
	margin-right: 9px;
}
/* Restrict Access directive */
.loader .alert{
	font-size: initial;
}
.body-designer .alert.restrictAccess {
	position: static;
	top: auto;
	left: auto;
	min-height: 70px;
	overflow: auto;
}
#requestAccess .alert.restrictAccess {
	overflow: auto;
}
/* Multiple files upload directive */
.uploader-drag-drop {
	background-color: rgba(188, 178, 178, 0.06);
}
.uploader-drag-drop .message {
	text-align: center;
	color: #777;
}
.uploader-drag-drop .message p {
	margin-top: 5px;
	margin-bottom: 5px;
}
.uploader-drag-drop .loading-bar::before {
	background-color: #c2f2ce;
}
.uploader-drag-drop .loading-bar.loaded-success::before {
	opacity: 1;
	background-color: #50c7c7;
}
.uploader-drag-drop .loading-bar.loaded-fail::before {
	opacity: 1;
	background-color: #e9726c;
}
.uploader-drag-drop .queued-file {
	margin: 5px;
}
.uploader-drag-drop .loading-bar {
	margin-top: 3px;
	margin-bottom: 21px;
}
.uploader-drag-drop .clickTag {
	color: orange;
}
.editMode .viewModeTag {
	display: block;
}
.viewModeTag {
	display: none;
}
.viewModeTag rect {
	fill: #c2daf2;
	stroke: #b4cae0;
	stroke-width: 1;
}
.guidelines .viewModeTag text {
	fill: rgb(0, 0, 0);
	font-style: normal;
	font-weight: 300;
}
.badge-unmarked {
	background-color: initial !important;
}
.badge-unmarked:hover {
    background-color: #356635 !important;
}
.caret-up,
.caret-down {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 2px;
	vertical-align: middle;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
	border-width: 4px;
	margin-top: 7px
}
.caret-up {
	border-bottom-color: #aaa;
	border-bottom: 4px dashed;
}
.caret-down {
	border-top-color: #aaa;
	border-top: 4px dashed;
}
.modal .table {
	color: inherit;
	left: 0;
}
.modal .table>tbody>tr:hover {
	background-color: inherit;
}
#replace-pricelist .alert-warning{
	margin-top: 40px;
	margin-bottom: 30px;
}
#sessionExpired .modal-body alert {
	position: static;
	top: auto;
	left: auto;
	min-height: 70px;
	overflow: auto;
}
.ui-history-user-upload-main .list-group-item, .ui-history-user-upload-main .list-group-item:hover {
	color: inherit !important;
	background-color: unset !important;
	border: 1px solid #e9e9e9 !important;
}
.ui-history-user-upload-main .list-group-item:last-of-type {
	border-bottom: 1px solid #e9e9e9 !important;
}
/* angular-ui-history */
.ui-history ul,
.ui-history ol {
	padding: 15px;
}
#chartjs-tooltip {
	opacity: 1;
	position: absolute;
	background: rgba(0, 0, 0, .7);
	color: white;
	border-radius: 3px;
	-webkit-transition: all .1s ease;
	transition: all .1s ease;
	pointer-events: none;
	-webkit-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
}
.chartjs-tooltip-key {
	display: inline-block;
	width: 10px;
	height: 10px;
	margin-right: 10px;
}
label.label-truncate {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.email-template-container .ql-container {
	min-height: 600px;
}
.link-disabled {
	border: 0;
	color: #777;
}
.link-disabled:hover{
	color: #777;
}

.scribble-editable {
	display: flex;
	flex-direction: column;
	overflow: auto;
	position: relative;
}
.scribble-actions {
	display: flex;
	padding: 0 8px !important;
	width: 100%;
}
.scribble-actions .navbar-form {
	border: 0;
	margin: 8px 0 !important;
	padding: 0;
}
.scribble-actions > .navbar-form > .btn-group {
	margin-right: 8px;
}
.scribble-actions,
.scribble-area {
	margin: 0 !important;
}

.scribble-actions .btn+.btn, .scribble-actions .btn-group>.btn+.btn {
	margin-left: 0!important;
}
.scribble-actions .btn {
	border: 0;
	border-radius: 4px;
}
.scribble-actions .btn.active {
	border-width: 1px;
}
.scribble-actions .btn.btn-default {
	border-color: #ccc;
}
.scribble-area .fa {
	width: 1.28571429em;
	padding: 8px;
}
.scribble-actions.navbar {
	margin: 0 auto;
}
.canvasWrapper canvas {
	position: absolute;
	top: 0;
	left: 0;
}
body{
	background-image: none!important;
}
.scribble-actions.navbar,
.scribble-board {
	background-color: #fff;
}
.scribble .scribble-area {
	border: 0;
	margin: 0 auto;
}
.scribble .btn.btn-fab>i.fa {
	margin-top: 4px;
}
#viewerContainer {
	background-color: #c9cdd4;
}
.scribble .btn-group+.btn-group {
	margin-left: 0!important;
}
.pdfjs .toolbar {
	z-index: 1;
}
.client-document-item {
	padding: 20px 15px;
	border-radius: 0.4em;
}
.client-document-item.active {
	/* background-color: #c9cdd4; */
	background-color: #ddd;
}
.client-document-item:hover{
	background-color: rgba(201,205,212, 0.5);
}
/*
 * pdfjs container fix for safari.
 * absolute postion to force height/width to be inherited from ancestor.
 */
.pdfjs {
	height: 100%;
	position: absolute;
	width: 100%;
}
.pdfViewer .page+.page{
	margin-top: 25px;
}
.scribble-area .btn.btn-success.btn-circular{
	display: none;
}

@media (max-width: 500px) {
	.toolbarViewerMiddle {
		margin: 0 !important;
	}

	.dropdownToolbarButton {
		display: none !important;
	}
}