ui-chat user {
	display: flex;
}
ui-chat user a {
	margin: 0;
	padding: 0;
	border: 0;
}
ui-chat .ui-chat-listing {
	overflow: hidden;
	text-overflow: ellipsis;
}

ui-chat .ui-chat-group  {
	float: left;
	font-size: 30px;
	margin: 8px 5px 0 5px;
	color: #404040;
}

/* Make ui-chat play nicely with waves-effect */
ui-chat .input-group-addon.waves-effect {
	display: table-cell;
	line-height: 12px;
}

ui-chat .ui-chat-window .ui-chat-window-body .vcol-11 {
	height: 85%;
}

ui-chat .ui-chat-window .ui-chat-window-body .vcol-1 {
	height: 15%;
}

.ui-chat-window {
	z-index: 40;
}
.ui-chat-window.ui-chat-window-room .dropdown-menu>li .dropdown-menu-collapse>li>button,
.ui-chat-window.ui-chat-window-room .dropdown-menu>li>a,
.ui-chat-window.ui-chat-window-room .dropdown-menu>li>button {
	background-color: #404040;
}
#ui-chat-modal-user-select .ui-chat-listing {
	background-color: unset;
	color: #fff;
	margin-top: 5px;
	margin-bottom: 5px;
}
.ui-chat-window-heading .btn.btn-default{
	background-color: transparent;
	color: white;
	margin-top: -10px;
}
.ui-chat-window-heading .btn.btn-default:hover{
	border:0;
}
.ui-chat-window i.ui-chat-group.fa-comments {
	width: 40px;
}
.ui-chat-window .ui-chat-window-heading{
	background-color:#595959!important;
}
.ui-chat-window {
	border-color:#595959!important;
}
.ui-chat-window.ui-chat-window-room .dropdown-menu>li .dropdown-menu-collapse>li>button, .ui-chat-window.ui-chat-window-room .dropdown-menu>li>a, .ui-chat-window.ui-chat-window-room .dropdown-menu>li>button{
	background-color:#595959!important;
}
.ui-chat-window .form-horizontal .form-control {
	background-color:#f0f0f0!important;
	border-color: #f0f0f0 #f0f0f0 #d7d7d7;
    border-top-color: rgb(240, 240, 240);
    border-right-color: rgb(240, 240, 240);
    border-bottom-color: rgb(215, 215, 215);
    border-left-color: rgb(240, 240, 240)
}