/* All Font-awesome icons should be a consistant width {{{ */
.fa {
	width: 14px;
	text-align: center;
}
/* }}} */
/* Client Quotation - contract Qty alignment fix {{{ */
.table td.quote-non-editable{
	padding-right: 15px !important;
}
/* }}} */

/* Chosen-js plugin */
.chosen-container .chosen-single .group-name:after{
	content:'' !important;
}
/*  */

/* timezone selector {{{ */
.chosen-search-input {
	color: #444;
}
/* }}} */

/* ui-grid missing icons {{{ */
.ui-grid-icon-plus-squared:before {
	content: '\c350'
}

.ui-grid-icon-minus-squared:before {
	content: '\c351'
}
/* }}} */

/* Modal: admin report product picker {{{ */
#product-picker-modal .modal-dialog {
	max-width: initial;
	width: 800px;
}
/* }}} */

/* ui-grids in modals {{{ */
.modal .ui-grid-viewport{
	padding: 0;
	margin: 0;
}
.modal .ui-grid-header {
	margin: 0;
}
.modal .ui-grid-row {
	margin: 0;
}
.modal .ui-grid-render-container::after{
	border-color: transparent;
}

.modal .grid-productList {
	background: transparent;
	color: white;
}

.modal .ui-grid-header {
	border-bottom-color: #8b8b8b !important;
}

.modal .ui-grid-row {
	border-bottom-color: #8b8b8b;
}
.modal .ui-grid-row:hover {
	background-color: #8b8b8b;
}

.modal .ui-grid-filter-input {
	border-color: #8b8b8b !important;
}
.modal .ui-grid-filter-input:focus,
.modal .ui-grid-filter-input:hover {
	background-color: #8b8b8b;
}

.modal .ui-grid-filter-select {
	height: 35px;
	border-color: #8b8b8b;
}
.modal .ui-grid-filter-select:focus,
.modal .ui-grid-filter-select:hover {
	background-color: #8b8b8b;
	border-color: #8b8b8b;
}

.modal .ui-grid-pager-control-input,
.modal .ui-grid-pager-row-count-picker > select {
	background-color: transparent;
	border-color: #8b8b8b;
	color: white;
}
.modal .ui-grid-pager-control-input:focus,
.modal .ui-grid-pager-control-input:hover,
.modal .ui-grid-pager-row-count-picker > select:focus,
.modal .ui-grid-pager-row-count-picker > select:hover {
	background-color: #8b8b8b !important;
	border-color: #8b8b8b !important;
}

.modal .btn-icon,
.modal .ui-grid-icon-menu,
.modal .ui-grid-column-menu-button {
	background-color: transparent;
	color: white;
}

.modal .btn-icon:focus,
.modal .btn-icon:hover,
.modal .ui-grid-icon-menu:focus,
.modal .ui-grid-icon-menu:hover,
.modal .ui-grid-column-menu-button:focus,
.modal .ui-grid-column-menu-button:hover {
	background-color: #6a6a6a !important;
	color: white;
}

.modal .ui-grid-menu-inner {
	background-color: #6a6a6a !important;
}

.modal .ui-grid-menu-item.ui-grid-menu-item-active,
.modal .ui-grid-menu-item:hover {
	background-color: #595959 !important;
}

.modal .ui-grid-pager-first:not([disabled]),
.modal .ui-grid-pager-previous:not([disabled]),
.modal .ui-grid-pager-next:not([disabled]),
.modal .ui-grid-pager-last:not([disabled]) {
	color: white;
}
.modal .ui-grid-pager-first:not([disabled]):focus,
.modal .ui-grid-pager-previous:not([disabled]):focus,
.modal .ui-grid-pager-next:not([disabled]):focus,
.modal .ui-grid-pager-last:not([disabled]):focus,
.modal .ui-grid-pager-first:not([disabled]):hover,
.modal .ui-grid-pager-previous:not([disabled]):hover,
.modal .ui-grid-pager-next:not([disabled]):hover,
.modal .ui-grid-pager-last:not([disabled]):hover {
	background-color: #6a6a6a !important;
	color: white;
}

.modal .ui-grid-pager-count {
	color: #aaa;
}
/* }}} */
/* ui-select {{{ */
.ui-select-choices-group-label.dropdown-header{
	padding-left: 10px!important;
}
.email-template-container .ui-select-choices-group-label.dropdown-header{
	font-weight: bold;
	background-color: #595959!important;
	color: white;
	opacity: 0.9;

}
.email-template-container .ui-select-choices-group .divider {
	margin-bottom: 0;
}
.email-template-container .ui-select-container {
	min-width: 170px;
	margin-left: 5px;
}
.token-hint.dropdown {
	float: none;
}
.token-hint .dropdown-menu,
.template-example .dropdown-menu {
	border: 1px solid rgb(240, 240, 240);
	padding: 10px;
	min-width: 250px;
}
.template-example .dropdown-menu {
	min-width: 230px;
}
.token-hint .dropdown-menu li {
	line-height: 17px;
}
/* }}} */
/* ui-notification {{{ */
.progress {
	background-color: rgba(0, 0, 0, 0.1);
	margin-top: 10px;
}
.ui-notification .progress .progress-bar {
	border: 1px solid #236aa7;
}
/* }}} */
 @media print {
	.ui-chat-window.print--hide{
		display: none!important;
	}
}