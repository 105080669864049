#modal-mgFormEditor-add,
#modal-mgFormEditor-edit {
	z-index: 300;
}
.mgComponent {
	width: 45%;
	display: inline-block;
	margin-right: 5px !important;
	vertical-align: top;
}
.dashboard-widgets #modal-mgFormEditor-add .fa-circle,
.dashboard-widgets .fa.fa-asterisk {
	color: transparent;
}
.dashboard-widgets .mgFormEditor-mask .dropdown-toggle {
	display: none!important;
}
.dashboard-widgets .card {
	width: 100%;
	border-color: transparent;
}
.dashboard-widgets .dashboard-card {
	border: 1px solid rgba(0,0,0,.125);
	margin: 0;
	height: 100%;
	min-height: 106pt;
	padding: 10px;
}
.dashboard-widgets .card .card-empty {
	position: absolute;
	top: 8vh;
	left: calc(50% - 106px);
}
.dashboard-widgets .card .card-no-data {
	position: absolute;
	top: 7vh;
	left: calc(50% - 40px);
}
.dashboard-widgets .nav-stacked>li {
	border-bottom: 0;
}
mg-form-editor a,
.dashboard-widgets a.fa-lock {
	border-bottom: 0;
}
.dashboard-widgets #modal-mgFormEditor-add .btn {
	height: inherit;
	padding-top: 6px;
	padding-bottom: 6px;
	line-height: 1.9em;
}
.dashboard-widgets #modal-mgFormEditor-add .fa {
	width: initial;
}
.dashboard-widgets #modal-mgFormEditor-add .fa.fa-4x {
	font-size: 1.8em;
}
/* FIXME: hover on 'Add widget' button bug. Temporarily
disabled all transitions */
.dashboard-widgets a,
.dashboard-widgets .btn {
	transition: unset;
}
mg-form-editor .mgFormEditor-mask .mgFormEditor-mask-buttons a.btn {
	padding: 4px 6px !important;
}
.mgFormEditor-mask-buttons .btn {
	border-radius: 5px;
	font-size: 14px;
}
.dashboard-card p {
	margin-top: 0;
}
.dashboard-widgets .widget-stats {
	width: 100%;
}
.dashboard-widgets .widget-stats, .dashboard-widgets .widget-stats th {
	text-align: center;
}
.dashboard-card-table {
	height: 120px;
	overflow: auto;
}
.dashboard-widgets .widget-stats td{
	padding: 12px 20px;
	border-bottom: 1px solid rgba(230,230,230,.7);
}
.card-control {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
}
.dashboard-widgets .dashboard-control {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
}
.dashboard-widgets .dashboard-card .row,
.gu-mirror .dashboard-card .row {
	margin-left: -15px;
	margin-right: -15px;
}
.dashboard-widgets .dashboard-card .row [class*=col-],
.gu-mirror .dashboard-card .row [class*=col-] {
	padding-right: 15px;
	padding-left: 15px;
}
.card-date {
	margin-left: 5px;
}
.mgComponentEditorInserter {
	display: none;
}
.dashboard-widgets [data-path='mgBlank']{
	display: none;
}

/* dragular does not render the gu-mirror modal within an mg-form */
mg-form .form-group {
	margin-bottom: 15px;
}
.dashboard-widgets .gu-transit{
	opacity: 1!important;
}