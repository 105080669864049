.productImageUpload label.btn-success{
	float: none;
	margin-left: 5px;
}
.productImageUpload label.text-success,
.productImageUpload .uploaderUploading{
	display: none;
}
.productImageUpload .loading-bar::before{
	background-color: #c2f2ce;
}
.productImageUpload .loading-bar{
	margin-top: 15px;
}
.productImageUpload .panel-default{
	margin-top: 20px;
	background-color: inherit;
	border: 1px solid #ddd;
}
.productImageUpload-result {
	border: 1px solid #ddd;
}
.productImageUpload .alert-danger{
	margin-top: 15px;
	margin-bottom: 15px;
}
.invalidDependency {
	margin-left: 20px;
	color: #f89406;
}
.invalidDependency i{
	margin-right: 5px;
}
.userGroup label.checkbox {
	white-space: nowrap;
}
.editJobType table {
	vertical-align: middle!important;
}
.editJobType table tbody td {
	padding-top: 0px!important;
	padding-bottom: 0px!important;
}
 .editJobType .table>tbody>tr>td,
 .editJobType .table>tbody>tr>th {
 	vertical-align: middle !important;
}
.form-horizontal label.checkbox.checkbox-multi{
	padding-left: 60px !important;
}
.form-horizontal label.checkbox.checkbox-multi{
	margin-left: 15px !important;
}
label.checkbox-multi>input.m-l-10-f { margin-left: 32px !important; }
.form-horizontal .form-group>label.label-wrap {
	overflow: visible;
	white-space: normal;
	line-height: 16px;
	margin-top: 9px;
}
.productEdit .form-group .ui-grid{
	padding-top: 0;
}
.userGroup .form-group .control-label.sticky-header {
	position: sticky;
	top: 0;
	font-weight: 700;
	background-color: #ddd;
	z-index: 2;
	text-align: center;
}
.userGroup .form-group .control-label.sticky-header-sub {
	height:50px;
	line-height: 15px;
	padding-top:12px
}
.userGroup .form-group .control-label.sticky-header span {
	float: left;
	padding-left: 5px;
	padding-right: 25px;
}
.userGroup .form-group .control-label.sticky-header span + span {
	padding-left: 0;
}