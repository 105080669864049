/**
* MFDC utility class
* This CSS extends the default Bootstrap base with some handy additional classes
*/
/* Padding {{{ */
.pad, .pad-sm, .pad-lg, .pad-xl {display:block;}
.pad, table.pad td {padding: 20px;}
.pad-xs, table.pad-xs td {padding: 5px;}
.pad-sm, table.pad-sm td {padding: 10px;}
.pad-lg, table.pad-lg td {padding: 30px;}
.pad-xl, table.pad-xl td {padding: 50px;}
.pad-top, .pad-top-xs, .pad-top-sm, .pad-top-lg, .pad-top-xl {display:block;}
.pad-top, table.pad-top td {padding-top: 20px;}
.pad-top-xs, table.pad-top-xs td {padding-top: 5px;}
.pad-top-sm, table.pad-top-sm td {padding-top: 10px;}
.pad-top-lg, table.pad-top-lg td {padding-top: 30px;}
.pad-top-xl, table.pad-top-xl td {padding-top: 50px;}
.pad-right, .pad-right-xs, .pad-right-sm, .pad-right-lg, .pad-right-xl {display:block;}
.pad-right, table.pad-right td {padding-right: 20px;}
.pad-right-xs, table.pad-right-xs td {padding-right: 5px;}
.pad-right-sm, table.pad-right-sm td {padding-right: 10px;}
.pad-right-lg, table.pad-right-lg td {padding-right: 30px;}
.pad-right-xl, table.pad-right-xl td {padding-right: 50px;}
.pad-bottom, .pad-bottom-xs, .pad-bottom-sm, .pad-bottom-lg, .pad-bottom-xl {display:block;}
.pad-bottom, table.pad-bottom td {padding-bottom: 20px;}
.pad-bottom-xs, table.pad-bottom-xs td {padding-bottom: 5px;}
.pad-bottom-sm, table.pad-bottom-sm td {padding-bottom: 10px;}
.pad-bottom-lg, table.pad-bottom-lg td {padding-bottom: 30px;}
.pad-bottom-xl, table.pad-bottom-xl td {padding-bottom: 50px;}
.pad-left, .pad-left-xs, .pad-left-sm, .pad-left-lg, .pad-left-xl {display:block;}
.pad-left, table.pad-left td {padding-left: 20px;}
.pad-left-xs, table.pad-left-xs td {padding-left: 5px;}
.pad-left-sm, table.pad-left-sm td {padding-left: 10px;}
.pad-left-lg, table.pad-left-lg td {padding-left: 30px;}
.pad-left-xl, table.pad-left-xl td {padding-left: 50px;}
/* }}} */
/* Font Sizes {{{ */
.font-xs {font-size: 8pt;}
.font-sm {font-size: 10pt;}
.font-md {font-size: 14pt;}
.font-lg {font-size: 16pt;}
.font-xlg {font-size: 20pt;}
/* }}} */

/* Margin {{{*/
.m-l--10 { margin-left: -10px; }
.m-l--15 { margin-left: -15px; }
.m-t--15 { margin-top: -15px; }
.m-t--10 { margin-top: -10px; }
.m-t-5 { margin-top: 5px!important; }
.m-t-10 { margin-top: 10px!important; }
.m-t-15 { margin-top: 15px; }
.m-t-20 { margin-top: 20px; }
.m-t-30 { margin-top: 30px !important; }
.m-t-50 { margin-top: 50px !important; }
.m-b-30 { margin-bottom: 30px !important; }
.m-r-0 { margin-right: 0px !important; }
.m-r-5 { margin-right: 5px; }
.m-r-10 { margin-right: 10px; }
.m-r-20 { margin-right: 20px; }
.m-b-5 { margin-bottom: 5px; }
.m-b-10 { margin-bottom: 10px !important; }
.m-b-20 { margin-bottom: 20px; }
.m-l-5 { margin-left: 5px; }
.m-l-10 { margin-left: 10px; }
.m-l-20 { margin-left: 20px; }
.m-l-30 { margin-left: 30px; }
.p-x-30 { padding-left: 30px!important; padding-right: 30px!important;}
.p-x-40 { padding-left: 40px!important; padding-right: 40px!important;}
.p-r-0 { padding-bottom: 0px; }
.p-l-5 { padding-left: 5px; }
.p-l-15 { padding-left: 15px; }
.p-l-25 { padding-left: 25px!important; }
.p-l-30 { padding-left: 30px!important; }
.p-r-25 { padding-right: 25px!important; }
.p-t-10 { padding-top: 10px!important; }
.p-t-15 { padding-top: 15px; }
.p-t-30 { padding-top: 30px!important; }
.p-b-15 { padding-bottom: 15px; }
.p-b-5 { padding-bottom: 5px; }
/* }}} */

.position-relative { position: relative; }
.position-static { position: static !important; }
.text-center{ text-align: center!important; }
.text-left{ text-align: left!important; }
.text-right{ text-align: right!important; }
.d-flex { display: flex!important; }
.justify-content-center{ justify-content: center; }
.justify-content-end{ justify-content: flex-end; }
.align-items-center{ align-items: center; }
.align-self-start{ align-self: flex-start;}
.float-left {float: left!important;}
.float-right {float: right!important;}
.w-100 { width: 100%;}
.h-100 { height: 100%;}
.border-bottom-0 { border-bottom: 0!important;}
.text-white {color: #ddd;}
