/*  --------------------------------------------------------------------------------
    Layout
    -------------------------------------------------------------------------------- */

    body {
        min-height: 500px;
        min-width: 1000px;
        overflow: auto;
    }

    /* body.body-plain */
    .body-plain {
        min-height: 0;
        min-width: 0;
    }

    /* Loader {{{ */
    /* block ui interaction while loading */
    body.loading-foreground::before {
        background: transparent;
        content: '';
        cursor: wait;
        display: block;
        height: 100%;
        position: fixed;
        width: 100%;
        z-index: 100;
    }

    .loader-spinner::before {
        border-color: #528FCC #528FCC transparent transparent;
    }

    .loader-bar::after,
    .loader-bar::before {
        background-color: #528FCC;
    }
    /* }}} */


/*  Header ------------------------------------------------------------------------- */

    .navbar {
        display: block;
        height: 50px;
        position: relative;
        width: 100%;
    }

    .navbar .container-fluid {
        padding: 0;
    }

    .navbar-header {
        float: left;
        margin: 0 !important;
    }

    .navbar-nav {
        float: left;
        margin: 0 !important;
    }
        .navbar-right {
            float: right;
        }

    .navbar-brand {
        margin: 0 !important;
        width: 250px;
    }

    .navbar .navbar-header,
    .navbar .navbar-nav {
        transform: none;
        transition: transform 0.2s ease-in-out;
    }
        .navbar > .container-fluid > .navbar-header,
        .navbar > .container-fluid > .navbar-nav {
            transform: translate3d(-250px, 0, 0);
        }
        @media ( max-width: 1000px ) {
            .navbar > .container-fluid > .navbar-header,
            .navbar > .container-fluid > .navbar-nav {
                transform: translate3d(-166px, 0, 0);
            }
        }

        .navbar > .container-fluid > .navbar-right,
        .sidebar--main--active .navbar > .container-fluid > .navbar-header,
        .sidebar--main--active .navbar > .container-fluid > .navbar-nav {
            transform: translate3d(0, 0, 0);
        }


/*  Container ---------------------------------------------------------------------- */

    .view {
        height: calc(100% - 50px);
        overflow: hidden;
        position: absolute;
        top: 50px;
        width: 100%;
    }


/*  Content ------------------------------------------------------------------------ */

    .content {
        border: 0 solid transparent;
        height: 100%;
        max-height: 100%;
        transition: border 0.2s ease-in-out;
        width: 100%;
    }
        .sidebar--main--active .content {
            border-left-width: 250px;
            transform: none;
        }
        .sidebar--calendar--active .content {
            border-right-width: 250px;
        }

    @media ( max-width: 1000px ) {
        .sidebar--main--active .content {
            border-left-width: 166px;
        }
        .sidebar--calendar--active .content {
            border-right-width: 166px;
        }
    }

    .content__container--y {
        height: 100%;
        overflow-y: auto;
        width: 100%;
    }


/*  Sidebar ------------------------------------------------------------------------ */

    .sidebar {
        background-color: white;
    }

    .sidebar--main {
        left: 0;
        position: absolute;
        top: 0;
        transform: translate3d(-250px, 0, 0);
        transition: transform 0.2s ease-in-out;
        width: 250px;
    }

    /* @media ( max-width: 1000px ) {
        .sidebar--main {
            transform: translate3d(-167px, 0, 0);
        }
    } */

    .sidebar--main--active .sidebar--main {
        transform: translate3d(0, 0, 0);
    }


/*  Product selection -------------------------------------------------------------- */

    .sidebar--products {
        bottom: 0;
        position: absolute;
        right: 0;
    }

    .sidebar--products--horizontal {
        height: 250px;
        width: 100%;
    }

    .sidebar--products--vertical {
        height: 100%;
        width: 250px;
    }