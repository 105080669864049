/* General styles {{{ */
	ui-chat a {
		color: #000;
		text-decoration: none;
		cursor: pointer;
	}
	.ui-chat-search input,
	.ui-chat-search input+span > a{
		background-color: #f0f0f0!important;
		border: 0;
	}
	ui-chat a:hover {
		text-decoration: none;
	}
	/* }}} */

	/* .ui-chat-window - General styling for all windows {{{ */
	ui-chat .ui-chat-window {
		position: fixed;
		bottom: 0px;
		width: 250px;
		max-height: 321px;
		background-color:#fff;
		border-top: 1px solid rgba(0, 0, 0, 0.07) !important;
		border-left: 1px solid rgba(0, 0, 0, 0.07)!important;
		border-right: 1px solid rgba(0, 0, 0, 0.07)!important;
	}

	/* .ui-chat-window-heading {{{ */
	ui-chat .ui-chat-window .ui-chat-window-heading {
		background-color: #404040;
		color: #FFF;
		width: 100%;
		height: 32px;
		padding-left: 10px;
	}

	ui-chat .ui-chat-window .ui-chat-window-heading .ui-chat-window-heading-text {
		display: inline-block;
		line-height: 32px;
		height: 32px;
		vertical-align: top;
	}

	ui-chat .ui-chat-window .ui-chat-window-heading .ui-chat-window-heading-menu {
		/* float: left; */
		/* margin: 2px 5px 0 0; */
	}

	ui-chat .ui-chat-window .ui-chat-window-heading .ui-chat-window-heading-controls {
		float: right;
		height: 100%;
	}
	/* }}} */

	/* .ui-chat-window-body {{{ */
	ui-chat .ui-chat-window .ui-chat-window-body {
		overflow: hidden;
		padding-bottom: 15px;
	}

	ui-chat .ui-chat-window.ui-chat-window-state-open .ui-chat-window-body {
		height: 300px;
	}

	ui-chat .ui-chat-window.ui-chat-window-state-minimized .ui-chat-window-body {
		display: none;
	}
	/* }}} */

	/* .ui-chat-window-alert {{{ */
	ui-chat .ui-chat-window .ui-chat-window-body .ui-chat-window-alert {
		text-align: center;
		color: #BBB;
		margin: 50px 10px 10px 10px;
	}

	ui-chat .ui-chat-window .ui-chat-window-body .ui-chat-window-alert > div:first-child {
		margin-bottom: 5px;
	}
	/* }}} */

	/* Scrollbars {{{ */
	ui-chat .ui-chat-window ::-webkit-scrollbar {
		height: 10px;
		width: 5px;
		transition: width 10s;
	}

	ui-chat .ui-chat-window ::-webkit-scrollbar-track {
		background: rgba(0, 0, 0, 0);
	}

	ui-chat .ui-chat-window:hover ::-webkit-scrollbar {
		background: rgba(0, 0, 0, 0.1);
	}

	ui-chat .ui-chat-window ::-webkit-scrollbar-track-piece {
	}

	ui-chat .ui-chat-window ::-webkit-scrollbar-thumb {
		background: rgba(0, 0, 0, 0);
		border-radius: 4px;
	}

	ui-chat .ui-chat-window:hover ::-webkit-scrollbar-thumb {
		background: rgba(0, 0, 0, 0.2);
	}
	/* }}} */

	/* Sizing methods {{{ */
	ui-chat .ui-chat-window .ui-chat-window-body .vcol-11 {
		height: 90%;
	}

	ui-chat .ui-chat-window .ui-chat-window-body .vcol-1 {
		height: 10%;
	}

	ui-chat .ui-chat-window .ui-chat-window-body .overflow-y {
		overflow-y: auto;
	}
	/* }}} */
	/* Padding methods {{{ */
	ui-chat .ui-chat-window .ui-chat-window-body .p-5 {
		padding: 5px;
	}
	/* }}} */
	/* }}} */

	/* .ui-chat-window-body {{{ */
	ui-chat .ui-chat-window-body {
		height: 400px;
	}
	/* }}} */

	/* .ui-chat-window-room - message display window instance {{{ */
	ui-chat .ui-chat-window.ui-chat-window-room .ui-chat-window-body {
		height: 240px;
		background-color: #e9eaed;
		font-size: 13px;
	}

	/* Message instances {{{ */
	ui-chat .ui-chat-window.ui-chat-window-room .ui-chat-window-body .ui-chat-message {
		display: block;
		margin: 45px 0 0 0;
	}

	ui-chat .ui-chat-window.ui-chat-window-room .ui-chat-window-body .ui-chat-message .ui-chat-message-date {
		font-size: 10px;
		color: #AAA;
		margin: -16px 10px 0 10px;
	}

	ui-chat .ui-chat-window.ui-chat-window-room .ui-chat-window-body .ui-chat-message.ui-chat-message-me .ui-chat-message-date {
		float: right;
	}

	ui-chat .ui-chat-window.ui-chat-window-room .ui-chat-window-body .ui-chat-message.ui-chat-message-other .ui-chat-message-date {
		float: left;
	}

	ui-chat .ui-chat-window.ui-chat-window-room .ui-chat-window-body .ui-chat-message .ui-chat-message-user {
		margin: -30px 0 0 0;
		display: block;
		color: #333;
	}

	ui-chat .ui-chat-window.ui-chat-window-room .ui-chat-window-body .ui-chat-message.ui-chat-message-me .ui-chat-message-user {
		float: right;
	}

	ui-chat .ui-chat-window.ui-chat-window-room .ui-chat-window-body .ui-chat-message .ui-chat-message-user.ui-chat-message-user-loading {
		width: 65px;
		height: 65px;
		text-align: center;
		padding-top: 20px;
		font-size: 40px;
		text-shadow: 1px 1px 2px black;
	}

	ui-chat .ui-chat-window.ui-chat-window-room .ui-chat-window-body .ui-chat-message.ui-chat-message-other .ui-chat-message-user {
		float: left;
	}

	ui-chat .ui-chat-window.ui-chat-window-room .ui-chat-window-body .ui-chat-message .ui-chat-message-content {
		display: block;
		padding: 10px;
		position: relative;
		margin-top: 40px;
		color: rgba(0, 0, 0, 0.54);
		font-size: 15px;
		background-color: #C8E6C9;
		border-radius: 3px;
		box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
	}

	ui-chat .ui-chat-window.ui-chat-window-room .ui-chat-window-body .ui-chat-message.ui-chat-message-me .ui-chat-message-content {
		margin: 0 90px 0 0;
		color: rgba(0, 0, 0, 0.65);
		background-color: #FFFFFF;
	}

	ui-chat .ui-chat-window.ui-chat-window-room .ui-chat-window-body .ui-chat-message.ui-chat-message-other .ui-chat-message-content {
		margin: 0 0 0 90px;
	}

	ui-chat .ui-chat-window.ui-chat-window-room .ui-chat-window-body .ui-chat-message .ui-chat-message-content:before {
		content: '';
		position: absolute;
		border-top: 16px solid rgba(0, 0, 0, 0.15);
		border-left: 16px solid transparent;
		border-right: 16px solid transparent;
	}

	ui-chat .ui-chat-window.ui-chat-window-room .ui-chat-window-body .ui-chat-message.ui-chat-message-me .ui-chat-message-content:before {
		margin: -9px -16px 0 0;
		right: 0;
	}

	ui-chat .ui-chat-window.ui-chat-window-room .ui-chat-window-body .ui-chat-message.ui-chat-message-other .ui-chat-message-content:before {
		margin: -9px 0 0 -16px;
		left: 0;
	}

	ui-chat .ui-chat-window.ui-chat-window-room .ui-chat-window-body .ui-chat-message .ui-chat-message-content:after {
		content: '';
		position: absolute;
		top: 0;
		border-top: 17px solid #C8E6C9;
		border-left: 17px solid transparent;
		border-right: 17px solid transparent;
	}

	ui-chat .ui-chat-window.ui-chat-window-room .ui-chat-window-body .ui-chat-message.ui-chat-message-me .ui-chat-message-content:after {
		content: '';
		right: 0;
		margin: 0 -15px 0 0;
		border-top: 17px solid #FFFFFF;
	}

	ui-chat .ui-chat-window.ui-chat-window-room .ui-chat-window-body .ui-chat-message.ui-chat-message-other .ui-chat-message-content:after {
		content: '';
		left: 0;
		margin: 0 0 0 -15px;
	}

	ui-chat .ui-chat-window.ui-chat-window-room .ui-chat-window-body .ui-chat-message.ui-chat-message-system {
		margin: 2px 0 0 0;
	}

	ui-chat .ui-chat-window.ui-chat-window-room .ui-chat-window-body .ui-chat-message.ui-chat-message-system .ui-chat-message-system-content {
		display: block;
		font-style: italic;
		text-align: center;
		color: #AAA;
		padding: 1px;
	}
	/* }}} */

	/* Post area {{{ */
	ui-chat .ui-chat-window.ui-chat-window-room .ui-chat-window-body .ui-chat-window-post {
		height: 22px;
		padding: 3px;
	}
	/* }}} */
	/* }}} */

	/* .ui-chat-listing - display area for a user / room listing {{{ */
	ui-chat .ui-chat-listing {
		color: #000;
		background: #FFF;
		display: flex;
		align-items: center;
		padding: 0 15px;
		border: 0;
		height: 50px;
		margin-bottom: 5px;
		padding-left: 10px;
	}

	ui-chat .ui-chat-listing:hover {
		color: #000;
		/* border-bottom:1px solid rgba(0, 0, 0, 0.07); */
		background: #DDE;
	}

	ui-chat .ui-chat-listing .ui-chat-listing-title {
		/* padding: 10px 0; */
	}

	ui-chat .ui-chat-listing .ui-chat-listing-status {
		/* float: right; */
		/* display: block; */
		font-size: 12px;
		/* margin: 14px 12px 0 2px; */
		color: grey;
	}

	ui-chat .ui-chat-listing .ui-chat-listing-control {
		float: right;
		display: block;
		margin: 6px 5px 0 2px;
	}

	ui-chat .ui-chat-listing .ui-chat-listing-status-available {
		color: #26c281;
	}

	ui-chat .ui-chat-listing .ui-chat-listing-status-partial {
		color: #ffce54;
	}

	ui-chat .ui-chat-listing .ui-chat-listing-status-unavailable {
		color: #eaeef0;
	}
	/* }}} */

	.remote-video-container video {
		width: 100%;
		z-index: 999;
	}

	.local-video-container video {
		width: 100%;
		z-index: 1000;
	}

	.local-video-container-small video {
		width: 30%;
		border-radius: 5px;
		position: absolute;
		right: 2px;
		bottom: 2px;
		border: 1px solid white;
		z-index: 1000;
	}

	.local-screen-container-small video {
		width: 30%;
		border-radius: 5px;
		position: absolute;
		right: 110px;
		bottom: 2px;
		border: 1px solid white;
		z-index: 1000;
	}

	.remote-screen-container-small video {
		width: 30%;
		border-radius: 5px;
		position: absolute;
		left: 2px;
		bottom: 2px;
		border: 2px solid green;
		z-index: 1000;
	}

	.video-window-local-video #local-video-container video,
	.video-window-local-screen #local-screen-container video,
	.video-window-remote-video #remote-video-container video,
	.video-window-remote-screen #remote-screen-container video {
		width: 100% !important;
		border-radius: 0 !important;
		position: static !important;
		border: none !important;
		z-index: 999 !important;
	}
	.ui-chat-window-heading-menu .btn.ui-chat-window-heading-controls-btn,
	.ui-chat-window-heading-controls .btn.ui-chat-window-heading-controls-btn{
		height: 32px;
		line-height: 32px;
		padding: 0!important;
		margin: 0!important;
		float: none;
		text-shadow: none!important;
		min-width: 25px;
	}

chat-navbar ul.head-list a{
	display: inline-block;
	padding-left: 10px;
	padding-right: 5px;
	padding-bottom: 5px;
	border: 0;
}
chat-navbar .dropdown-menu{
	padding-top:10px;
	border: 1px solid rgba(0,0,0,.07);
	border-top: 0;
}
chat-navbar .bord-top {
	border-top: 1px solid rgba(0,0,0,.07);
}
ui-chat .img-circle {
	object-fit: cover;
}
.notification-badge {
	position: absolute;
	top: 1em;
	left: 1.6em;
	background-color: #f0f0f0;
	border-radius: 50%;
}
.ui-chat-window-heading .btn.ui-chat-window-heading-controls-btn:hover{
	background-color: transparent;
	color: unset;
}