/*  --------------------------------------------------------------------------------
    Calendar sidebar
    -------------------------------------------------------------------------------- */

    .sidebar--calendar {
        height: 100%;
        position: absolute;
        top: 0;
        transform: translate3d(100%, 0, 0);
        transition:
            border 2s ease-in-out,
            transform 0.2s ease-in-out;
        width: 100%;
        z-index: 1;
    }
    .sidebar--calendar--active .sidebar--calendar {
        transform: translate3d(0, 0, 0);
    }
    .sidebar--main--active.sidebar--calendar--active .sidebar--calendar {
        transform: translate3d(250px, 0, 0);
    }

    .sidebar--calendar--toggle-view,
	.sidebar--calendar--config-view,
	.sidebar--calendar--add-slot,
	.sidebar--calendar--timezone {
        position: absolute !important;
        right: 15px;
        top: 10px;
        transform: translate3d(250px, 0, 0);
        transition:
            background-color 0.2s ease-in-out,
            transform 0.2s ease-in-out !important;
        z-index: 10;
    }
		.sidebar--calendar--config-view {
			/* right: 95px; */
			right: 55px;
		}
		.sidebar--calendar--add-slot {
			/* right: 55px; */
			right: 95px;
		}
		.sidebar--calendar--timezone {
			right: 145px;
		}
        .sidebar--calendar--active .sidebar--calendar--toggle-view {
            transform: translate3d(0, 0, 0);
        }

		.sidebar--calendar--active .sidebar--calendar--config-view,
		.sidebar--calendar--active .sidebar--calendar--add-slot,
		.sidebar--calendar--active .sidebar--calendar--timezone {
            transform: translate3d(0, 0, 0);
        }

        .sidebar--calendar {
            border-right: 0px solid transparent;
        }

        .sidebar--main--active .sidebar--calendar {
            border-right-width: 250px;
        }

        .sidebar--calendar--active.sidebar--calendar--active .sidebar--calendar {
            transform: translate3d(100%, 0, 0) translate3d(-250px, 0, 0);
        }

        .sidebar--calendar--active.sidebar--calendar--full .sidebar--calendar {
            transform: translate3d(0, 0, 0);
        }

        .sidebar--calendar--active.sidebar--calendar--full.sidebar--main--active .sidebar--calendar {
            transform: translate3d(250px, 0, 0);
        }

    @media ( max-width: 1000px ) {
        .sidebar--calendar--active .sidebar--calendar--config-view {
            transform: translate3d(45px, 40px, 0);
        }
        .sidebar--calendar--active .sidebar--calendar--add-slot {
            transform: translate3d(45px, 40px, 0);
        }
        .sidebar--calendar--active .sidebar--calendar--timezone {
            transform: translate3d(45px, 40px, 0);
        }
        .sidebar--main--active.sidebar--calendar--active .sidebar--calendar {
            transform: translate3d(166px, 0, 0);
        }
        .sidebar--main--active .sidebar--calendar {
            border-right-width: 166px;
        }
        .sidebar--calendar--active.sidebar--calendar--active .sidebar--calendar {
            transform: translate3d(100%, 0, 0) translate3d(-166px, 0, 0);
        }
        .sidebar--calendar--active.sidebar--calendar--full .sidebar--calendar {
            transform: translate3d(0, 0, 0);
        }
        .sidebar--calendar--active.sidebar--calendar--full.sidebar--main--active .sidebar--calendar {
            transform: translate3d(166px, 0, 0);
        }
    }





/*  --------------------------------------------------------------------------------
    Mini calendar
    -------------------------------------------------------------------------------- */

    .clndr {
        overflow: hidden;
        width: 217px;
    }
        .clndr .controls {
            font-size: 20px;
        }
    @media ( max-width: 1000px ) {
        .clndr {
            width: 147px;
        }
    }

    .day,
    .day-header {
        display: inline-block;
        height: 30px;
        line-height: 30px;
        padding-left: 3px;
        text-align: left;
        width: 31px;
    }
        .day {
            font-size: 12px;
        }
            .day.last-month,
            .day.next-month {
                opacity: 0.5;
            }

        .day-header {
            font-weight: 500;
            text-transform: lowercase;
        }

    @media ( max-width: 1000px ) {
        .day,
        .day-header {
            width: 21px;
        }
	}

	/* Calendar in modal window */
	.modal-content .quickdate-popup {
		color: initial;
	}
	.modal-content .quickdate-popup .disabled-date{
		color: #b3b0b0f7;
	}
	.modal-content .quickdate-popup  td.disabled-date:hover {
		background-color: inherit	 !important;
		cursor: initial;
	}
	/* Calendar - Resource view */
	th.fc-resource-cell {
		font-weight: 500;
	}
	/* Completed tasks */
	.taskCompleted .fc-content::before {
		content: '\f00c';
		font: 13px FontAwesome;
		float: left;
		margin-right: 4px;
	}
	/* All Day Events */
	.allDayEvent .fc-content::before {
		content: '\f274';
		font: 13px FontAwesome;
		float: left;
		margin-right: 4px;
	}
	/* UserGroup appointments in calendar */
	.sidebar--calendar--detail .fc-content {
		min-height: 34px;
	}
	.fc-event .fc-content {
		white-space: normal;
		height: 68px;
	}
	/* Edit Booking - Invalid date*/
	.invalidRepeatStart,
	.invalidRepeatEnd {
		position: absolute;
		right: 30px;
		top: 13px;
	}
	.invalidRepeatEnd {
		right: 0;
	}
	/* Booking manager */
	.booking-search-panel {
		margin-top: 50px;
		min-height: 305px;
		height: calc(100vh - 195px);
	}
	.booking-search-panel .checkbox label,
	.booking-search-panel .radio label{
		white-space: nowrap;
		margin-bottom: -5px;
	}
	.booking-search-panel h3 {
		clear: both;
		font-weight: 600;
		padding-top: 30px;
	}
	.booking-search-panel .search-option {
		overflow: hidden;
		height: calc(50% - 104px);
		width: 100%;
		overflow-y: auto !important;
	}
	.booking-search-panel h3:first-of-type {
		padding-top: 0;
	}
	.calendar-toolbar {
		float: right;
	}
	/* Timezone selector */
	.appointment-calendar .calendar-options,
	.appointment-calendar .chosen-container {
		width: 100% !important;
	}
	.hide-element{
		visibility: hidden;
	}
	.calendar-control-time {
		padding-top: 7px;
	}

/* Calendar popover */
.calendar-event-popover {
	position: relative;
	overflow: hidden;
}
.calendar-event-popover button.close {
	position: absolute;
	top: -2px;
	right: 0
}
.event-view-more::after {
	position: relative;
	top: 3px;
	height: 0;
	width: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid #8b8b8b;
	content: '';
	display: inline-block;
	margin: 5px;
	transform: rotate(-90deg);
}