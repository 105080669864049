.line {
	fill: none;
	stroke: black;
	stroke-width: 1.5px;
}

.line:hover {
	stroke: rgba(0, 0, 255, 0.4);
}
.line.selected {
	stroke: rgba(0, 0, 255, 0.6);
}
.annotation-text {
	font-size: 12pt;
	font-family: 'Helvetica';
	text-anchor : middle;
	dominant-baseline : central;
}
.annotation-text.selected {
	stroke: rgba(0, 0, 255, 0.4);
}
.annotation-text.selected::selection {
	background: transparent;
}
.panel .designerAnnotation{
	stroke: inherit;
}
.selection-box {
	fill: rgba(0, 0, 255, 0.1);
	stroke: rgba(0, 0, 255, 0.4);
	stroke-width: 0.5;
}
/* TODO: remove contour--focused*/
.mode--select .designerRectangle:hover .shape-area {
}
#replaceOverlayMessage {
	background: rgba(0, 0, 0, 0.5);
	font-size: 20px;
	text-align: center;
	position: absolute;
	width: 100%;
	height: 100%;
	padding-top: 20%;
	color: white;
}
#context-menu .handle {
	position: relative;
	width: 10px;
	height: 10px;
	top: -10px;
	left: -10px;
	margin-bottom: -10px;
	cursor: move;
}
/*@@@@@*/
button::-moz-focus-inner {
	padding: 0;
	border: 0;
}
hr {
	border-top: 1px solid #dddddd;
	margin-top: 30px;
}
body.body-designer {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.file-upload-drop-area {
	padding: 100px;
	text-align: center;
	background: #F0F0F0;
}
.file-upload.btn {
	vertical-align: bottom;
	float: none;
}
.email-template-container .fa-spinner {
	width: inherit;
}
/* MC added CSS {{{ */
.fullscreen {
	width: 100%;
	height: 100%;
}

#layers .panel-body {
	color: #8e8e8e;
}

.modal .modal-dialog .modal-content .modal-header code.pull-right {
	margin-right: 10px;
}

/* }}} */

/* KV added CSS {{{ */
/* Splines */
.splineMarker {
	fill : black;
}

/* Measurement tool */
.measurementLine {
	stroke : black;
	fill : none;
	stroke-width : 1;
	marker-start : url(#TODO);
	marker-end : url(#TODO);
}

.measurementText {
	font-size : 20px;
	stroke-width : 1;
}

/* Shapes tool */
.resizer {
	fill : red;
}

.splineMarker {
	fill : darkblue;
}

.designerShape {
	stroke-width : 1;
}
/* }}} */

/* body.print-mode - Print mode (also applied when in @print context by jQuery) {{{ */
.print-mode{
	overflow: hidden !important;
}
/* Simple element hiding */
.print-mode .navbar, .print-mode .sidebar, .print-mode .sidebar--products {
	display: none;
}

.print-mode .view {
	top: 0px;
	height: 100%;
	width: 100%;
}

.print-mode .canvas {
	stroke: none;
}

.print-mode .content {
	border: 0 !important;
	height: 100%;
	transform: none !important;
	width: 100%;
}

.print-mode .layer > g {
	/*transform: translate(0); FIXME: overrides plan zoom level*/
}
.print-mode .grid rect{
	fill: none !important;
}
/* Hover over favourite style dropdown */
.btn-group--tool-palette .dropdown-menu.favouriteStyle {
	z-index: 1000;
}
/* }}} */

/* Save Job errors {{{*/
.body-designer .saveJob-error.alert {
	position: static;
	top: auto;
	left: auto;
	z-index: 50;
}

.body-designer .saveJob-retry {
	z-index: 50;
	touch-action: manipulation;
	cursor: pointer;
	position: relative;
}
/*}}}*/

/* Layer edit {{{ */
#layer-edit .miniview .fa-spinner{
	position: absolute;
	top: 50%;
	left: 50%;
	width: inherit;
	color:inherit;
}
#layer-edit .uploaderUploading .fa-spinner {
	width: inherit;
}
#job-busy {
	z-index: 3000;
	margin-left: -4px;
}
.cropper-view-box img{
    position: static !important;
}
/* }}} */
#layer-edit .radio [type="radio"]{
	top: 8px;
}
#layer-edit .modal-body__section .pull-right {
	margin-right: 15px;
}
.list-group .form-horizontal .checkbox {
	margin-top: 7px;
	margin-bottom: 5px;
	margin-right: -8px;
}

/* Rescale Layer Background */
.modal.rescale-background .modal-dialog{
	position: absolute;
	top: 5px;
	right: 5px;
	width: 300px;
	height: auto;
}
.form-horizontal label.checkbox.checkbox--right{
	width: 100%;
	line-height: 20px;
	padding-left: 0!important;
}
.checkbox.checkbox--right input {
	left: auto;
	right: 0;
}
.dropdown.dropdown-category .collapse > li > button {
	border-left-width: 25px;
}
.dropdown.dropdown-category {
	background-color: #6a6a6a
}
/* Package categories dropdown */
.package-dropdown-menu-btn::after{
	position: relative;
	top: 3px;
	height: 0;
	width: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid #8b8b8b;
	content: '';
	display: inline-block;
	margin: 5px;
	transition: transform .2s ease-in-out;
}
.package-dropdown-menu-btn.active::after{
	transform: rotate(-90deg);
}
.designerEllipse svg{
	overflow: visible;
}